import React from 'react'

const Services = () => {
  return (
    <div>
      service
    </div>
  )
}

export default Services