import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import axios from 'axios';
import { Zoom, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



<script src="https://smtpjs.com/v3/smtp.js">
</script>


console.log(window.document.body.offsetWidth);
if (atob(localStorage.getItem('auth')) != "Yes" && atob(localStorage.getItem('auth')) != "No") {
  localStorage.setItem("auth", btoa("No"))
}
// localStorage.setItem("auth", btoa("No"))

axios.defaults.baseURL = process.env.REACT_APP_BACKENDURL;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <StrictMode>
      <App />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Zoom}
      />
    </StrictMode>
  </Router>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
